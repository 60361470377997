import {useContext} from 'react';
import AppContext from '../AppContext';
import Image from 'next/image';
import IconClose from 'aac-components/components/Icons/Basic/IconClose';
import SharedModalContent from './SharedModalContent';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import {ModalLinks} from './SharedModalContent';
import IconHeart from 'aac-components/components/Icons/Basic/IconHeart';
import Video from 'aac-components/components/Video';

export const GetHelpModalContentDesktop = ({setShowModal}) => {
    return (
        <div className="modal__background">
            <div
                className="modal__close"
                onClick={() => setShowModal(false)}
                title="Close Modal">
                <IconClose fill="#fff" />
            </div>
            <div className="modal__container">
                <div className="modal__left">
                    <div className="aac__logo">
                        <Image
                            src="https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/aac-logo-horizontal-white-text-dark-logo.svg"
                            width={0}
                            height={0}
                            sizes="50vw"
                            alt="American Addiction Centers"
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                    <h3>We Treat the Whole Person</h3>
                    <p>
                        When you come to AAC for substance treatment services, we see you
                        as more than your addiction. You come to us with unique strengths,
                        potential, and challenges; we believe that your abuse treatment
                        should be personalized, too.
                    </p>
                    <ModalLinks
                        links={[
                            {
                                href: '/why-choose-aac',
                                Icon: IconHeart,
                                iconFill: '#B00020',
                                title: 'Why Choose Us',
                            },
                        ]}
                    />
                    <div className="modal__video">
                        <div className="modal__video--video">
                            <Video
                                videoSrc="https://www.youtube.com/embed/w3U1STXhXHs?si=YvdhDEPYoqhhJBVD"
                                type="youtube"
                                title="Jimmy"
                            />
                        </div>
                        <div className="modal__video--title">
                            Jimmy - American Addiction Centers Review
                        </div>
                    </div>
                </div>
                <div className="modal__right">
                    <SharedModalContent />
                </div>
            </div>
            <style jsx>
                {`
                    .modal__background {
                        background: var(--gray-500);
                        width: 100vw;
                        height: 100vh;
                        overflow-y: auto;
                    }
                    .modal__container {
                        padding: 80px;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 80px;
                        margin: 0 auto;
                        max-width: ${MAX_WIDTH_PX};
                    }
                    h3 {
                        color: #fff;
                    }
                    p {
                        color: #fff;
                    }
                    .aac__logo {
                        height: 48px;
                        width: fit-content;
                        position: relative;
                        margin-bottom: 40px;
                        text-align: left;
                    }
                    .modal__close {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        cursor: pointer;
                    }
                    .modal__video {
                        margin: 40px 0;
                    }
                    .modal__video--video {
                        box-shadow: var(--box-shadow);
                        border-radius: 4px;
                        overflow: hidden;
                    }
                    .modal__video--title {
                        margin-top: 24px;
                        color: #fff;
                        font-weight: bold;
                        text-align: center;
                    }
                    .modal__background::-webkit-scrollbar {
                        width: 8px;
                    }
                    .modal__background::-webkit-scrollbar-track {
                        background: var(--interactive-100);
                        width: 4px;
                    }
                    .modal__background::-webkit-scrollbar-thumb {
                        background: var(--interactive-300);
                        border-radius: 2em;
                    }
                `}
            </style>
        </div>
    );
};
